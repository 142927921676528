
import Assignments from '@/views/Assignments.vue'
import Dashboard from '@/views/admin/Dashboard.vue'
import Plans from '@/views/Plans.vue'
import PlanUploader from '@/views/PlanUploader.vue'
import UserManager from '@/views/admin/UserManager.vue'
import Live from '@/views/admin/LiveReviews.vue'
import Moderated from '@/views/admin/ModeratedReviews.vue'

import { Role } from '../../_helpers/role'

let adminRoutes = [
  {
    path: '/admin',
    name: 'AdminDashboard',
    component: Dashboard,
    children: [
      {
        path: 'users',
        name: 'AdminUserManagement',
        component: UserManager,
        meta: {
          authorize: [Role.Super, Role.Admin]
        }
      },
      {
        path: 'upload',
        name: 'AdminPlanUpload',
        component: PlanUploader,
        meta: {
          authorize: [Role.Super, Role.Admin]
        }
      },
      {
        path: 'assign',
        name: 'AdminAssignments',
        component: Assignments,
        meta: {
          authorize: [Role.Super, Role.Admin]
        }
      },
      {
        path: 'list',
        name: 'AdminPlans',
        component: Plans,
        meta: {
          authorize: [Role.Super, Role.Admin]
        }
      },
      {
        path: 'live',
        name: 'LiveReviews',
        component: Live,
        meta: {
          authorize: [Role.Super, Role.Admin]
        }
      },
      {
        path: 'moderated',
        name: 'ModeratedReviews',
        component: Moderated,
        meta: {
          authorize: [Role.Super, Role.Admin]
        }
      },
    ]
  }
]

export default adminRoutes
