import Dashboard from '@/views/super/Dashboard.vue'
import UserManager from '@/views/super/UserManager.vue'
import OrgManager from '@/views/super/OrganisationManager.vue'

import { Role } from '../../_helpers/role'

let superAdminRoutes = [
  {
    path: '/super',
    name: 'SuperAdminDashboard',
    component: Dashboard,
    children: [
      {
        path: 'users',
        name: 'SuperAdminUserManagement',
        component: UserManager,
        meta: {
          authorize: [Role.Super]
        }
      },
      {
        path: 'orgs',
        name: 'SuperAdminOrgManagement',
        component: OrgManager,
        meta: {
          authorize: [Role.Super]
        }
      }
    ]
  }
]

export default superAdminRoutes
