import Vue from 'vue'
import Router from 'vue-router'

import Home from'@/views/Home.vue'
import Login from '@/views/Login.vue'
import OtpVerification from '@/views/OtpVerification.vue'

import RequestPasswordReset from '@/views/RequestPasswordReset.vue'
import PasswordResetEntry from '@/views/PasswordResetEntry.vue'
import SupportDashboard from '@/views/support/Dashboard.vue'
import ResetPassword from '@/views/ResetPassword.vue'
import SuperAdminRoutes from '@/router/super'
import AdminRoutes from '@/router/admin'
import ReviewerRoutes from '@/router/reviewer'
import ReporterRoutes from '@/router/reporter'
import FeedbackRoutes from '@/router/feedback'

import { getUser } from '@/services/auth.service'
import { Role } from '../_helpers/role'

import store from '../store'

Vue.use(Router)

let BasicRoutes = [
  {
    path: '/',
    name: 'home',
    component: Home
  },
  {
    path: '/login',
    name: 'login',
    component: Login
  },
  {
    path: '/OtpVerification',
    name: 'otpVerification',
    component: OtpVerification
  },
  {
    path: '/forgotten',
    name: 'RequestPasswordReset',
    component: RequestPasswordReset
  },
  {
    path: '/reset/:token',
    name: 'PasswordReset',
    component: PasswordResetEntry
  },
  {
    path: '/users/reset/:token',
    name: 'reset',
    component: ResetPassword,
    props: true
  },
  {
    path: '/support',
    name: 'SupportDashboard',
    component: SupportDashboard,
    meta: {
      authorize: [...Object.values(Role)]
    }
  },
  { path: '*', redirect: '/' }
]

let routes = [
  ...BasicRoutes,
  ...SuperAdminRoutes,
  ...AdminRoutes,
  ...ReviewerRoutes,
  ...ReporterRoutes,
  ...FeedbackRoutes
]

let router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {

  const { authorize } = to.meta;
  const currentUser = getUser()

  window.dataLayer = window.dataLayer || []
  
  function gtag () { window.dataLayer.push(arguments) }
  
  gtag('set', 'page_path', to.path)
  gtag('event', 'page_view')


  if (to.name === 'otpVerification') {
    if (authorize) {  
      if (currentUser && authorize.length && !authorize.includes(currentUser.role)) {
        return next({ path: '/' })
      }
    }
  }

  if (authorize) {
    if (!currentUser) {
      return next({ path: '/login', query: { returnUrl: to.path } });
    }

    if (authorize.length && !authorize.includes(currentUser.role)) {
      return next({ path: '/' })
    }
  }

  const reviewRoutes = ['ReviewConfirmation', 'PerformReview']
  const hasActiveAssignment = store.getters['assignment/currentAssignment']

  if (reviewRoutes.includes(to.name) && !hasActiveAssignment) {
    return next('/review/select')
  }

  next()
})

export default router
