import Axios from 'axios'
import moment from 'moment'

import {
  getAccessToken,
  setAccessToken,
  getRefreshToken,
  setRefreshToken,
  getUser,
  logout,
} from './auth.service.js'

import router from '../router'

import { validatePrimaryNeed, validateCurrentSocialCare } from '../_helpers'

let isRefreshingAccessToken = false
const refreshSubscribers = []

const headersParams = {
  'Cache-Control': 'no-cache, no-store',
  'Pragma': 'no-cache, no-store',
  'Content-Security-Policy': "frame-ancestors 'none'",
  'Strict-Transport-Security': 'max-age=31536000; includeSubDomains',
  'X-Content-Type-Options': 'nosniff',
  'X-Frame-Options': 'DENY',
  'Referrer-Policy': 'no-referrer',
}

const axios = Axios.create({ 
  baseURL: process.env.VUE_APP_API_URL,
  headers: headersParams,
})

const unInterceptedAxios = Axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  headers: headersParams
})

function subscribeTokenRefresh(callback) {
  refreshSubscribers.push(callback)
}

function onAccessTokenRefreshed(accessToken) {
  refreshSubscribers.map((callback) => callback(accessToken))
}

function refreshAccessToken() {
  return new Promise((resolve, reject) => {
    unInterceptedAxios
      .post('/api/v1/auth/refresh-tokens', { refreshToken: getRefreshToken() })
      .then((response) => {
        if (!response) {
          throw new Error("We couldn't refresh access token.")
        }

        const {
          data: { access, refresh },
        } = response
        setAccessToken(access.token)
        setRefreshToken(refresh.token)
        
        axios.defaults.headers.common.Authorization = `Bearer ${access.token}`
        
        resolve(access.token)
      })
      .catch((error) => reject(error))
  })
}

axios.interceptors.response.use(
  (response) => {
    return response
  },
  (error) => {
    console.log({error})
    const {
      config,
      response: { status },
    } = error
    const originalRequest = config

    if (status === 401) {
      if (!isRefreshingAccessToken) {
        isRefreshingAccessToken = true
        refreshAccessToken()
          .then((newToken) => {
            isRefreshingAccessToken = false
            onAccessTokenRefreshed(newToken)
          })
          .catch(() => {
            router.push('/login')
            logout()
          })
      }

      const retryOriginalRequest = new Promise((resolve) => {
        subscribeTokenRefresh((token) => {
          originalRequest.headers['Authorization'] = 'Bearer ' + token
          resolve(axios(originalRequest))
        })
      })

      return retryOriginalRequest
    } else {
      return Promise.reject(error)
    }
  }
)

export default class ApiService {
  constructor() {
    this.axios = axios
    this.unInterceptedAxios = unInterceptedAxios
    this._accessToken = getAccessToken()
    this._user = getUser()
    if (this._accessToken) {
      this.axios.defaults.headers.common.Authorization = `Bearer ${this._accessToken}`
    }
  }

  get accessToken() {
    return this._accessToken
  }

  set accessToken(token) {
    this._accessToken = token
    this.axios.defaults.headers.common.Authorization = `Bearer ${this._accessToken}`
    setAccessToken(this._accessToken)
  }

  async verifyOtpCode(otpCode) {
    try {
      const { data: { tokens, user, tries }} = await unInterceptedAxios.post('/api/v1/auth/verify-verification-code', { otpCode }, { withCredentials: true })

      if (user && tokens?.access && tokens?.refresh) {
        this.accessToken = tokens.access.token

        setRefreshToken(tokens.refresh.token)
      }

      return { user, tokens, tries }
    } catch (error) {
      console.log({ error })
      throw error
    }
  }

  async resendOtpCode() {
    try {
      const res = await unInterceptedAxios.get('/api/v1/auth/resend-verification-code', { withCredentials: true })

      return res
    } catch (error) {
      throw error
    }
  }

  async hasOtpSession() {
    try {
      const res = await unInterceptedAxios.get('/api/v1/auth/verification-session', { withCredentials: true })

      return res
    } catch (error) {
      throw error
    }
  }

  async loginWithEmailAndPassword(email, password) {

    try {
      // MF { withCredentials: true }
      const res = await unInterceptedAxios.post('/api/v1/auth/login', { email, password }, { withCredentials: true })
      
      const { data: { tokens, user }} = res

      if (user && !user.twoFactorAuth && tokens) {
        this.accessToken = tokens.access.token

        setRefreshToken(tokens.refresh.token)
      }

      return { user, tokens }
    } catch (error) {
      throw error
    }
  }

  requestPasswordReset(email) {
    return unInterceptedAxios.post('/api/v1/auth/forgot-password', { email })
  }

  resetPassword(token, password) {
    try{
      return unInterceptedAxios.post('/api/v1/auth/reset-password', {
        password,
        token,
      })

    } 
    catch(err) {
      console.log({err})
    }
  }

  verifyToken(token) {
    return unInterceptedAxios.post('/api/v1/auth/verify-token', {
      token,
    })
  }

  // Organisations
  fetchAllOrganisations() {
    return axios.get('/api/v1/organisations')
  }

  createOrganisation(organisation) {
    return axios.post('/api/v1/organisations', organisation)
  }

  updateOrganisation(organisation) {
    return axios.put('/api/v1/organisations/' + organisation.id, organisation)
  }

  // Users
  fetchAllUsers() {
    return axios.get('/api/v1/users')
  }

  fetchUsersForOrganisation(organisationId) {
    return axios.get(`/api/v1/organisations/${organisationId}/users`)
  }

  addUserToOrganisation(user, organisationId) {
    return axios.post(`/api/v1/organisations/${organisationId}/users`, user)
  }

  updateUserOrganisation(user, organisationId) {
    return axios.put(
      `/api/v1/organisations/${organisationId}/users/${user.id}`,
      user
    )
  }
  updateUserTwoFactorAuth(user) {
    return axios.put(`api/v1/users/twoFactorAuth/${user.id}`, user)
  }

  // Plans
  uploadPlansForOrganisation(plans, organisationId) {
    plans.forEach((plan) => {
      // Force data into correct format for Sequelize
      plan.finalisedDate = moment(plan.finalisedDate, 'DD/MM/YYYY').format(
        'YYYY-MM-DD[T]HH:mm:ss'
      )

      plan.primaryNeed = validatePrimaryNeed(plan.primaryNeed)

      plan.currentSocialCare = validateCurrentSocialCare(
        plan.currentSocialCare
      )
    })
    return axios.post(
      `/api/v1/organisations/${organisationId}/plans/bulk`,
      plans
    )
  }

  fetchPlansForOrganisation(organisationId) {
    return axios.get(`/api/v1/organisations/${organisationId}/plans`)
  }

  // Assignments
  archiveAssignment(organisationId, assignmentId) {
    return axios.post(
      `/api/v1/organisations/${organisationId}/assignments/${assignmentId}/archive`
    )
  }

  createAssignmentsForOrganisation(assignments, organisationId) {
    return axios.post(
      `/api/v1/organisations/${organisationId}/assignments/bulk`,
      assignments
    )
  }

  fetchAssignmentsForOrganisation(organisationId) {
    return axios.get(`/api/v1/organisations/${organisationId}/assignments`)
  }

  fetchAssignmentsForUserInOrganisation(organisationId, userId) {
    return axios.get(
      `/api/v1/organisations/${organisationId}/users/${userId}/assignments`
    )
  }

  // Reviews
  fetchReviewsForOrganisation(organisationId) {
    return axios.get(`/api/v1/organisations/${organisationId}/review`)
  }

  createReviewForOrganisation(review, organisationId) {
    return axios.post(`/api/v1/organisations/${organisationId}/review`, review)
  }

  updateReviewForOrganisation(review, organisationId) {
    return axios.put(
      `/api/v1/organisations/${organisationId}/review/${review.reviewId}`,
      review
    )
  }

  submitReviewForOrganisation(review, organisationId) {
    return axios.post(
      `/api/v1/organisations/${organisationId}/review/${review.reviewId}`,
      review
    )
  }

  generateReviewReport(reviewId) {
    return axios.get(`/api/v1/pdf/review/${reviewId}`)
  }

  // Feedback
  fetchFeedbackForOrganisation(organisationId) {
    return axios.get(`/api/v1/feedback/organisation/${organisationId}`)
  }

  fetchFeedbackForReview(organisationId, reviewId) {
    return axios.get(`/api/v1/feedback/${organisationId}/${reviewId}`)
  }

  fetchFeedbackById(id) {
    return axios.get(`/api/v1/feedback/${id}`)
  }

  submitReviewFeedback(feedback) {
    return axios.post('/api/v1/feedback', feedback)
  }

  getReportForAuditsInRange(startDate, endDate) {
    return axios.get(
      `/api/v1/reviews/export?start=${startDate}&end=${endDate}`
    )
  }
}
