import Dashboard from '@/views/reviewer/Dashboard.vue'
import ReviewList from '@/views/reviewer/ReviewList.vue'
import ReviewTemplate from '@/views/reviewer/ReviewTemplate.vue'
import MonitoringTheme from '@/views/reviewer/MonitoringTheme.vue'
import ReviewSummary from '@/views/reviewer/GradingSummary.vue'
import ReviewConfirmation from '@/views/reviewer/ReviewConfirmation.vue'

import { Role } from '../../_helpers/role'

export default [
  {
    path: '/review',
    name: 'ReviewerDashboard',
    component: Dashboard,
    children: [
      {
        path: 'select',
        name: 'ReviewList',
        component: ReviewList,
        meta: {
          authorize: [Role.Admin, Role.Audit]
        }
      },
      {
        path: 'confirm',
        name: 'ReviewConfirmation',
        component: ReviewConfirmation,
        meta: {
          authorize: [Role.Admin, Role.Audit]
        }
      },
      {
        path: 'theme/:id',
        name: 'PerformReview',
        component: ReviewTemplate,
        props: true,
        meta: {
          authorize: [Role.Admin, Role.Audit]
        }
      },
      {
        path: 'theme/6',
        name: 'MonitoringTheme',
        component: MonitoringTheme,
        props: true,
        meta: {
          authorize: [Role.Admin, Role.Audit]
        }
      },
      {
        path: 'summary',
        name: 'ReviewSummary',
        component: ReviewSummary,
        meta: {
          authorize: [Role.Admin, Role.Audit]
        }
      }
    ]
  }
]