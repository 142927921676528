/* eslint-disable no-undef */
export const getAccessToken = () => localStorage.getItem('access_token')
export const setAccessToken = (token) => localStorage.setItem('access_token', token)

export const getRefreshToken = () => localStorage.getItem('refresh_token')
export const setRefreshToken = (token) => localStorage.setItem('refresh_token', token)

export const getUser = () => JSON.parse(localStorage.getItem('user'))
export const setUser = (user) => localStorage.setItem('user', JSON.stringify(user))

export const isTokenExpired = () => {
  try {
    const [, payload] = localStorage.getItem('token').split('.')
    const { exp } = JSON.parse(window.atob(payload))
    const now = Date.now()
    const expms = exp * 1000
    return now > expms
  } catch (error) {
    console.log({ error })
    return false
  }
}

export const logout = () => {
  localStorage.removeItem('token')
  localStorage.removeItem('user')
}

export const isAuthorised = role => getUser().role === role
