import Vue from 'vue'
import Vuex from 'vuex'
import createLogger from 'vuex/dist/logger'

import organisation from '@/store/modules/organisation'
import user from '@/store/modules/user'
import plan from '@/store/modules/plan'
import assignment from '@/store/modules/assignment'
import criteria from '@/store/modules/criteria'
import review from '@/store/modules/review'
import feedback from '@/store/modules/feedback'

Vue.use(Vuex)

const store = new Vuex.Store({
  plugins: process.env.NODE_ENV !== 'production' ? [createLogger()] : [],
  modules: {
    organisation,
    user,
    plan,
    assignment,
    criteria,
    review,
    feedback
  }
})

export default store
