import FeedbackForm from '@/views/feedback/FeedbackForm.vue'
import FeedbackSubmitted from '@/views/feedback/FeedbackSubmitted.vue'

export default [
  {
    path: '/opinion/:type/:planId',
    name: 'FeedbackFormView',
    component: FeedbackForm,
    props: true
  },
  {
    path: '/feedback/submitted',
    name: 'FeedbackSubmittedView',
    component: FeedbackSubmitted
  }
]