import Dashboard from '@/views/reporter/Dashboard.vue'
import CustomGraphs from '@/views/reporter/CustomGraphs.vue'
import FurtherData from '@/views/reporter/FurtherData.vue'
import ExplanatoryComments from '@/views/reporter/ExplanatoryComments.vue'
import Reports from '@/views/reporter/Reports.vue'
import ThemeComments from '@/views/reporter/ThemeComments.vue'
import QueryBuilder from '@/views/reporter/QueryData.vue'
import ExperientialData from '@/views/reporter/Experiential.vue'
import OutcomesMonitoring from '@/views/reporter/OutcomesMonitoringGraphs.vue'
import ExportAudits from '@/views/reporter/ExportAudits.vue'

import { Role } from '../../_helpers/role'

export default [
  {
    path: '/reports',
    name: 'ReporterView',
    component: Dashboard,
    children: [
      {
        path: 'dashboard',
        name: 'ReportDashboard',
        component: Reports,
        meta: {
          authorize: [...Object.values(Role)]
        }
      },
      {
        path: 'custom',
        name: 'CustomReports',
        component: CustomGraphs,
        meta: {
          authorize: [...Object.values(Role)]
        }
      },
      {
        path: 'gradings',
        name: 'GradingReports',
        component: FurtherData,
        meta: {
          authorize: [...Object.values(Role)]
        }
      },
      {
        path: 'themecomments',
        name: 'ThemeComments',
        component: ThemeComments,
        meta: {
          authorize: [...Object.values(Role)]
        }
      },
      {
        path: 'explanatorycomments',
        name: 'ExplanatoryCommentsReport',
        component: ExplanatoryComments,
        meta: {
          authorize: [...Object.values(Role)]
        }
      },
      {
        path: 'queries',
        name: 'QueryBuilder',
        component: QueryBuilder,
        meta: {
          authorize: [...Object.values(Role)]
        }
      },
      {
        path: 'experiential',
        name: 'ExperientialData',
        component: ExperientialData,
        meta: {
          authorize: [...Object.values(Role)]
        }
      },
      {
        path: 'outcomesmonitoring',
        name: 'OutcomesMonitoring',
        component: OutcomesMonitoring,
        meta: {
          authorize: [...Object.values(Role)]
        }
      },
      {
        path: 'exportaudits',
        name: 'ExportAudits',
        component: ExportAudits,
        meta: {
          authorize: [...Object.values(Role)]
        }
      }
    ]
  }
]